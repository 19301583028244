import React from 'react'

const Footer = ({course}) => {
  if(!course.data)return<></>
  
  var courseName;
  if(course){
    courseName = course?.data.courseName;
  }
  else{
    return<></>;
  }
  return (
    <footer className="footer">
      <div className="container">
        <p className="copy-r">
        &copy; {new Date().getFullYear()} Crystal Springs Resort. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer