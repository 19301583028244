import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Home, Qr, Login, MyRewards, TeeSheet, CartWaiverForm, SlotConfirm, CheckInConfirm, StarterSheet, AdminMenu, UpdateGolferInfo, Override, Stats, Settings, UserManagement
} from "./components";
import { Header, Footer } from "./common";
import { ToastContainer } from 'react-toastify';
import AWN from "awesome-notifications"

const notifier = new AWN() // where options is an object with your custom values


const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const course_detail = useSelector((state) => state.courseSlice);

  useEffect(() => {
    if (course_detail.courseDetail === null) {
      switch (path) {
        case "/golf-tee-sheet":
        case "/slot-confirm":
        case "/checkin-confirm":
          navigate("/");
          break;
        case "/starter-sheet":
        case "/admin-menu":
        case "/update-golfer-info":
        case "/override-info":
        case "/stats":
        case "/settings":
        case "/user-management":
          navigate("/login");
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <>
      {course_detail.courseDetail && (
        <Header course={course_detail.courseDetail} />
      )}
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/golf-tee-sheet" element={<TeeSheet notifier={notifier} />} />
        <Route exact path="/slot-confirm" element={<SlotConfirm />} />
        <Route exact path="/cart-waiver-form" element={<CartWaiverForm />} />
        <Route exact path="/checkin-confirm" element={<CheckInConfirm />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/Home/MyRewards" element={<MyRewards/>} />
        <Route exact path="/admin-menu" element={<AdminMenu />} />
        <Route exact path="/starter-sheet" element={<StarterSheet />} />
        <Route exact path="/update-golfer-info" element={<UpdateGolferInfo />} />
        <Route exact path="/override-info" element={<Override />} />
        <Route exact path="/stats" element={<Stats />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/user-management" element={<UserManagement />} />
        {/* <Route path="/scan-qr" element={<Qr />} /> */}
      </Routes>
      {course_detail.courseDetail && (
        <Footer course={course_detail.courseDetail} />
      )}
    </>
  );
};

export default App;
