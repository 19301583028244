//Get the button
// When the user scrolls down 20px from the top of the document, show the button
if(window.location.pathname == '/cart-waiver-form'){

    window.onscroll = function () {
        scrollFunction();
    };
}

function scrollFunction() {
    var mybutton = document.getElementById("btn-back-to-top");
    if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
    ) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}
// When the user clicks on the button, scroll to the top of the document
// mybutton.addEventListener("click", backToTop);

export default function backToTop() {
    //   document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}