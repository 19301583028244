import React from 'react'
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="sec sec-golf">
        <div className="container">
          <div className="golf-top">
            <h2>Settings</h2>

          </div>
          <div className="menu-box">
            <div className="row">

              <div className="col-md-6">
                <div className="menu-box-col">
                  <a href="#">
                    <img src="images/menu-icon2.png" alt="" />
                    <span>Starter Sheet Settings</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="menu-box-col">
                  <a href="#">
                    <img src="images/menu-icon5.png" alt="" />
                    <span>Content Updates</span>
                  </a>
                </div>
              </div>


              <div className="col-md-12">
                <div className="menu-box-col text-center">
                  <button type="button" className="btn custom-btn back-btn" onClick={() => navigate(-1)}><i className="fal fa-long-arrow-left"></i> BACK</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Settings