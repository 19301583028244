import React, { useEffect } from 'react'
import {
    useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';


const CheckInConfirm = () => {
    const navigate = useNavigate();
    const golferForm = useSelector((state) => state.tee.golferForm);
    const booking_slot_info = useSelector((state) => state.tee.booking_slot_info);

    useEffect(() => {
        document.addEventListener('contextmenu', event => event.preventDefault());

        document.addEventListener('keydown', (e) => {
            e = e || window.event;
            if (e.keyCode == 116 || (e.ctrlKey && e.keyCode == 82)) {
                e.preventDefault();
            }
        });

        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
    })

    if (golferForm) {
        var { teeTimeDatetime, slotNumber } = golferForm;
    }
    const msg = booking_slot_info?.msg.split('!')
    return (
        <>
            <section className="sec">
                <div className="container" onClick={() => navigate("/checkin-confirm")}>
                    <div className="confirmation-box">
                        <div className="confirmation-icon"><img src="images/confirmation-icon.png" alt="" /></div>
                        {booking_slot_info && <p className="confirmation-text"><span>{msg[0]}!</span><br />{msg[1]}</p>}
                        {teeTimeDatetime && slotNumber && <p className="confirmation-time"><img src="images/watch-icon1.png" alt="" />  {moment(teeTimeDatetime).format("LT")} Slot {slotNumber}</p>}
                        <a href="#">Enjoy Your Round!</a>
                        <p className="confirmation-n">For more information about the loyalty program, please visit  <a href="#">www.csloyalty.com</a></p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckInConfirm