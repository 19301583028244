import React, { useEffect } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { GetInstructionByFormName } from "../../slices/instructionSlice";
import { clear_slot_data, GetEncryptedUniqueLink } from "../../slices/TeeSlice";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { GetSlotAvailabilityBySlotNo } from "../../slices/TeeSlice";

export default function Modal({ visible, model, modelData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit, setValue,
    formState,
  } = useForm({ mode: 'onChange' });

  const { isDirty, isValid, errors } = formState;

  const tee = useSelector((state) => state.tee);
  const tee_link_sent = tee.link_sent_data;
  const slot_available = useSelector((state) => state.tee.slot_available);
  const instruction = useSelector((state) => state.instruction.mobileSubmissionInfo);
  if (modelData) {
    var { id, teeTimeDatetime, playerShortName, shortEmail, shortPhoneNo, captainName, isCaptain, courseId, confirmationId, slotNumber, ticketId } = modelData;
  }

  const formName = {
    FormName: "Mobile.Submission",
  };
  useEffect(() => {
    if (modelData) {
      let slotData = {
        PosID: id,
        CourseId: courseId,
        TeeTimeDatetime: teeTimeDatetime,
        SlotNumber: slotNumber,
        ConfirmationId: confirmationId
      }
      dispatch(GetSlotAvailabilityBySlotNo(slotData));
    }


    if (!instruction) {
      dispatch(GetInstructionByFormName(formName));
    }

    return () => dispatch(clear_slot_data());
  }, [modelData])

  function mask(value) {
    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    setValue("number", x);
  }

  const onSubmit = (data) => {
    let dataTosend = {
      PosId: id,
      CourseId: courseId,
      ConfirmationId: confirmationId,
      TeeTimeDatetime: teeTimeDatetime,
      SlotNumber: slotNumber,
      PhoneNumber: data.number,
      CaptainName: captainName,
      IsCaptain: isCaptain,
      TicketId: ticketId
    };
    dispatch(GetEncryptedUniqueLink(dataTosend));

    // if (tee_link_sent?.statusCode === 200) {
    //   navigate('/slot-confirm');
    // }
  }


  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-dialog modal-dialog-centered">
        {slot_available === false && slot_available !== null && <><div className="modal-content">
          <div className="modal-header py-3">
            <div className="m-header-row">
              <button
                type="button"
                className="back"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="fal fa-long-arrow-left"></i>
                </span>
              </button>
              <h5 className="modal-title" id="exampleModalLongTitle">
                Slot Not Available
              </h5>
              <button
                type="button"
                className="back"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="far fa-times"></i>
              </button>
            </div>
          </div>
        </div></>}
        {slot_available && slot_available !== null && <><div className="modal-content">
          <div className="modal-header">
            <div className="m-header-row">
              <button
                type="button"
                className="back"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="fal fa-long-arrow-left"></i>
                </span>
              </button>
              <h5 className="modal-title" id="exampleModalLongTitle">
                You selected
              </h5>
              <button
                type="button"
                className="back"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="far fa-times"></i>
              </button>
            </div>
          </div>
          <div className="modal-body text-center">
            <p className="slot-time">
              <img src="images/watch-icon1.png" alt="" /> {moment(teeTimeDatetime).format("LT")}
            </p>
            <p className="slot-name">Slot {slotNumber}</p>
            {!playerShortName.startsWith("Guest") && <p className="slot-name">{playerShortName}</p>}
            {!playerShortName.startsWith("Guest") && <p className="slot-name-det">{shortPhoneNo}</p>}
            <p className="slot-name-det">{shortEmail}</p>
            {captainName && <p className="slot-name-det">{!isCaptain && <>Guest of {captainName}</>}</p>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="type-here">
                <input type="text" placeholder={instruction} {...register("number", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 14,
                    message: "10 digits required (xxx) xxx-xxxx"
                  },
                  onChange: (e) => mask(e.target.value)
                })} />
                {errors.number && (
                  <span className="text-danger">
                    {errors.number.message}
                  </span>
                )}
              </p>
              <button
                type="submit"
                className="btn custom-btn"
                data-bs-dismiss={errors.number?.message ? "" : "modal"}
                disabled={errors.number?.message || !isValid || !isDirty}
              >
                SUBMIT <i className="fal fa-long-arrow-right"></i>
              </button>
            </form>
          </div>
        </div></>}
      </div>
    </div>
  )
}
