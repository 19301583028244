import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetail } from "../../slices/courseSlice";
import { GetInstructionByFormName } from "../../slices/instructionSlice";
import { GetDecryptedUniqueLink, AddBookingSlot } from "../../slices/TeeSlice";
import Loader from "../../common/Loader/Loader";
import moment from "moment";
import { EditGolferDetails } from '../../slices/golferSlice';
import ReactTooltip from 'react-tooltip';
import GolfCartWaiver from "../GolfCartWaiver/GolfCartWaiver";
import backToTop from './btn-back-to-top'
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const CartWaiverForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const uniqueLink = new URLSearchParams(search).get('uniqueLink');
  const {
    register,
    handleSubmit,
    formState: { errors }, setValue
  } = useForm();
  const instruction = useSelector((state) => state.instruction.golferInfo);
  const golferForm = useSelector((state) => state.tee.golferForm);
  const course_detail = useSelector((state) => state.courseSlice);
  const booking_slot_info = useSelector((state) => state.tee.booking_slot_info);
  const [checkValue2, setCheckValue2] = useState(true);
  const [checkValue3, setCheckValue3] = useState(true);
  const [editable, seteditable] = useState(false);
  const [cart_waiver_display, set_cart_waiver_display] = useState(false);
  const [privacy_policy_display, set_privacy_policy_display] = useState(false);
  const [marketing_communication, set_marketing_communication] = useState(false);

  const [loader, setLoader] = useState(false);

  const formName = {
    FormName: "Golfer.Form",
  };

  const uniqueURL = {
    urlData: uniqueLink
  }

  if (golferForm && golferForm !== "pending" && golferForm?.data !== null) {
    var { golferId, teeTimeDatetime, slotNumber, captainName, isCaptain, firstName, lastName, confirmationId, courseId, email, phone, zipCode, posId, isCartWaiverChecked, isMarketingChecked, isPrivacyChecked, ticketId } = golferForm.data;
  }

  useEffect(() => {
    if (!instruction) {
      dispatch(GetInstructionByFormName(formName));
    }
    if (golferForm === null) {
      dispatch(GetDecryptedUniqueLink(uniqueURL));
    }
    if (golferForm && golferForm !== "pending" && golferForm?.data !== null) {
      dispatch(getCourseDetail({
        CourseId: golferForm.data.courseId,
      }));
    }
    if (booking_slot_info?.statusCode === 200 && booking_slot_info?.responseCode === "Success") {
      navigate("/checkin-confirm");
      setLoader(false);
    }
    if (golferForm && golferForm !== "pending" && golferForm?.data !== null) {
      firstName && setValue("firstname", firstName);
      lastName && setValue("lastname", lastName);
      email && setValue("email", email);
      phone && mask(phone);
      zipCode && setValue("zipcode", zipCode);
      // setValue("check1", isCartWaiverChecked);
      setValue("check2", isPrivacyChecked);
      setValue("check3", isMarketingChecked);
      (!firstName && !lastName && !email && !zipCode) && seteditable(true);
    }
  }, [booking_slot_info, golferForm])

  function mask(value) {
    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    setValue("number", x);
  }



  if (course_detail && course_detail.courseDetail) {
    document.title = course_detail.courseDetail ? course_detail.courseDetail.data.courseName : "loading..."
  }

  if (golferForm && golferForm?.data === null) {
    document.title = golferForm?.msg;
  }

  const editDetails = () => {
    seteditable(!editable);
  }

  const onSubmit = (data) => {
    setLoader(true);

    // let golferData = {
    //   GolferId: golferId,
    //   FirstName: data.firstname,
    //   LastName: data.lastname,
    //   Email: data.email,
    //   Phone: data.number,
    //   ZipCode: data.zipcode
    // };

    // let bookingSlotData =
    // {
    //   posid: posId,
    //   teetimeid: null,
    //   confirmationid: confirmationId,
    //   courseid: courseId,
    //   slotnumber: slotNumber,
    //   TicketId: ticketId,
    //   teetimedatetime: teeTimeDatetime.toUpperCase(),
    //   golferdetailslist: {
    //     GolferId: golferId,
    //     firstname: data.firstname,
    //     lastname: data.lastname,
    //     isverified: null,
    //     email: data.email,
    //     Phone: data.number,
    //     ZipCode: data.zipcode,
    //     Status: 1,
    //     IsPrivacyChecked: null,
    //     IsMarketingChecked: data.check3
    //   },
    //   CartWaverDetails: {
    //     CourseId: courseId,
    //     Descriptions: "CartWaiver Details",
    //     GolferId: golferId,
    //     IsCartWaiver: data.check1
    //   }
    // };

    let bookingSlotData =
    {
      posid: posId,
      teetimeid: null,
      confirmationid: confirmationId,
      courseid: courseId,
      slotnumber: slotNumber,
      TicketId: ticketId,
      teetimedatetime: teeTimeDatetime.toUpperCase(),
      golferdetailslist: {
        GolferId: golferId,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        Phone: data.number,
        ZipCode: data.zipcode,
        Status: 1,
        IsPrivacyChecked: null,
        IsMarketingChecked: data.check3
      },
      CartWaverDetails: {
        CourseId: courseId,
        Descriptions: "CartWaiver Details",
        GolferId: golferId,
        IsCartWaiver: data.check1
      }
    };

    // dispatch(EditGolferDetails(golferData));

    dispatch(AddBookingSlot(bookingSlotData));
  };

  return (
    <>
      <section className="sec sec-golf">
        <div className="container">
          {golferForm && golferForm !== "pending" && golferForm?.data !== null && <div className="golf-top px-2">
            {/* <h2>Cart Waiver Form <i className="fas fa-info-circle" data-tip={instruction}></i> <ReactTooltip place="bottom" multiline={true} className="w-50" /></h2> */}
            {instruction && <p>
              {instruction}
            </p>}
          </div>}

          {(golferForm === null || golferForm === "pending") && <div style={{
            width: '100%', display: 'flex',
            justifyContent: 'center'
          }}><Loader /></div>}

          {(golferForm && golferForm?.data === null) && <div style={{
            width: '100%', display: 'flex',
            justifyContent: 'center'
          }}><h3 style={{
            color: 'red'
          }}> {golferForm?.msg}</h3></div>}

          {
            golferForm && golferForm !== "pending" && golferForm?.data !== null &&
            <>
              <div className="golfer-box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="golfer-head-col">
                        <h4>
                          <img src="images/watch-icon1.png" alt="" />  {moment(teeTimeDatetime).format("LT")} Slot {slotNumber}
                        </h4>
                      </div>
                    </div>
                    <div className="col-10 col-lg-5">
                      <div className="golfer-head-col">
                        {!isCaptain && <h4>Guest of {captainName}</h4>}
                      </div>
                    </div>
                    <div className="col-2 col-lg-1">
                      <div className="golfer-head-col text-center" onClick={editDetails}>
                        {golferForm && golferForm !== "pending" && golferForm?.data !== null && editable ? <i className="fas fa-pencil" style={{ cursor: 'pointer' }}></i> : <i className="far fa-pencil" style={{ cursor: 'pointer' }}></i>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="golfer-form-col">
                        <input
                          className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="First Name*"
                          disabled={!editable}
                          {...register("firstname", {
                            required: {
                              value: true,
                              message: "Please enter full first name.",
                            },
                            minLength: {
                              value: 2,
                              message: "minimum 2 character required",
                            },
                            maxLength: {
                              value: 26,
                              message: "maximum 26 character required",
                            },
                            pattern: {
                              value: /^[A-Z a-z ,.'-]+$/i,
                              message: "Not valid",
                            },
                            onChange: (e) => { setValue("firstname", e.target.value); }
                          })}
                        />
                        {errors.firstname && errors.firstname && (
                          <span className="text-danger">
                            {errors.firstname.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="golfer-form-col">
                        <input
                          type="text"
                          className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                          placeholder="Last Name*"
                          disabled={!editable}
                          {...register("lastname", {
                            required: {
                              value: true,
                              message: "Please enter full last name.",
                            },
                            minLength: {
                              value: 2,
                              message: "minimum 2 character required",
                            },
                            maxLength: {
                              value: 26,
                              message: "maximum 26 character required",
                            },
                            pattern: {
                              value: /^[A-Z a-z ,.'-]+$/i,
                              message: "Not valid",
                            },
                            onChange: (e) => { setValue("lastname", e.target.value); }
                          })}
                        />
                        {errors.lastname && errors.lastname && (
                          <span className="text-danger">
                            {errors.lastname.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="golfer-form-col">
                        <input
                          type="text"
                          placeholder="9735555555"
                          className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                          disabled={true}
                          {...register("number",
                            {
                              onChange: (e) => mask(e.target.value)
                            })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="golfer-form-col">
                        <input
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Email*"
                          disabled={!editable}
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Please enter valid email address.",
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address"
                            },
                            onChange: (e) => { setValue("email", e.target.value); }
                          })}
                        />
                        {errors.email && errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="golfer-form-col">
                        <input
                          type="text"
                          className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                          placeholder="Zip Code*"
                          disabled={!editable}
                          {...register("zipcode", {
                            required: {
                              value: true,
                              message: "Please enter valid zip code.",
                            },
                            minLength: {
                              value: 5,
                              message: "minimum 5 character required",
                            },
                            onChange: (e) => { setValue("zipcode", e.target.value); }
                          })}
                        />
                        {errors.zipcode && errors.zipcode && (
                          <span className="text-danger">
                            {errors.zipcode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="golfer-form-col">
                        <ul>
                          <li className="golfer-check">
                            <input
                              type="checkbox"
                              id="golfer-check1"
                              {...register("check1", { required: true })}
                            />
                            <label htmlFor="golfer-check1">
                              I accept<a href="#cart-waiver" className="text-primary" onClick={() => { set_cart_waiver_display(!cart_waiver_display); set_privacy_policy_display(false); set_marketing_communication(false) }}> <u>cart waiver</u> <span className="text-danger">*</span> </a><br />
                              {errors.check1 && errors.check1.type === "required" && (
                                <span className="text-danger">
                                  <b>Must accept Cart Waiver.</b>
                                </span>
                              )}
                            </label>

                          </li>
                          {/* <li className="golfer-check">
                            <input
                              type="checkbox"
                              id="golfer-check2"
                              checked={checkValue2}
                              onClick={() => setCheckValue2(!checkValue2)}
                              {...register("check2", { required: true })}
                            />
                            <label htmlFor="golfer-check2">
                              I agree to the <a href="#privacy-policy" className="text-primary" onClick={() => { set_privacy_policy_display(!privacy_policy_display); set_cart_waiver_display(false); set_marketing_communication(false); }}> <u>Resort privacy policy</u> <span className="text-danger">*</span> </a>

                              <br />
                              {errors.check2 && errors.check2.type === "required" && (
                                <span className="text-danger">
                                  <b>Must agree to Privacy Policies.</b>
                                </span>
                              )}
                            </label>
                          </li> */}
                          <li className="golfer-check">
                            <input type="checkbox" id="golfer-check3"
                              checked={checkValue3}
                              onClick={() => setCheckValue3(!checkValue3)}
                              {...register("check3", { required: false })}
                            />
                            <label htmlFor="golfer-check3">
                              Opt into loyalty program and <a href="#marketing-communication" className="text-primary" onClick={() => { set_marketing_communication(!marketing_communication); set_cart_waiver_display(false); set_privacy_policy_display(false) }}> <u>marketing</u> </a><br />

                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="golfer-form-col text-center">
                        {loader ? <><Loader /><br /></> :
                          <><button type="submit" className="btn custom-btn" id="cart-waiver-submit">
                            Finish
                            <i className="fal fa-long-arrow-right"></i>
                          </button> <br /></>
                        }
                        <label className="text-center mt-4">
                          Learn more about the <a href="#privacy-policy" className="text-primary" onClick={() => { set_privacy_policy_display(!privacy_policy_display); set_cart_waiver_display(false); set_marketing_communication(false); }}> <u>Resort Privacy Policy</u> </a>
                        </label>
                      </div>
                    </div>
                    <div id="marketing-communication" className="justify-content-center align-items-center mb-2 mt-2" style={{ display: marketing_communication ? "flex" : "none", border: '2px solid black' }}>
                      <div className="p-2 text-center">
                        By opting-in for marketing communications, you agree to receive e-mails from the Resort about upcoming events and promotions.
                        {/* <br /><button type="button" className="btn mt-1" style={{backgroundColor:'#7B835E', color:'#fff'}}>Download</button> */}
                      </div>
                    </div>
                    <div id="privacy-policy" className="mt-2" style={{ display: privacy_policy_display ? "block" : "none", border: '2px solid black' }}>
                      <PrivacyPolicy />
                    </div>
                    <div id="cart-waiver" className="mt-2" style={{ display: cart_waiver_display ? "block" : "none", border: '2px solid black' }}>
                      <GolfCartWaiver />
                    </div>
                  </div>
                </form>
              </div>
            </>}
        </div>
      </section>
      <button
        type="button"
        className="btn btn-floating btn-lg"
        id="btn-back-to-top"
        onClick={() => backToTop()}
      >
        <i className="fas fa-arrow-up"></i>
      </button>
    </>
  );
};

export default CartWaiverForm;
