import React from 'react'
import { Link } from 'react-router-dom';
const privacyPolicyPDF = require('../../public/pdf/PRIVACY POLICY.pdf')

const PrivacyPolicy = () => {
    return (
        <>
            <section className="sec">
                <div className="container">
                    <h1> PRIVACY POLICY</h1>

                    <p></p><h4>Collection of your Personal Information</h4>
                    We will ask you when we need information that personally identifies you (personal information) or allows us to contact you. The personal information we collect might include your name, title, company or organization name, e-mail, day and evening phone, mobile phone, home address, and/or credit card information.
                    <p></p>
                    <p>This website may collect certain information about your visit, such as the name of the Internet service provider and the Internet Protocol (IP) address through which you accessed the Internet; the date and time you accessed our website; the pages that you accessed while here; and the Internet address of the website from which you linked directly to our site. This information is used to help analyze trends and improve users’ experience when visiting our website.</p>
                    <p></p>
                    <p></p><h4>Cookies</h4>
                    We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.
                    <p></p>
                    <p>
                        Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</p>
                    <p>
                    </p><h4>Use of your Personal Information</h4>
                    We may use your personal information to provide information about the Resort or upcoming events and promotions. When you register for our e-newsletter, you agree to receive promotional e-mail from the Resort.
                    <p></p>
                    <p>Any and all personal information collected from you will be used only by Crystal Springs Resort except as stated below.</p>
                    <p>In some cases, we may share information with authorized Third parties for the purpose of gathering additional publicly available information that can help us improve how we market the Resort to our guests. These authorized third parties are not permitted to use your personal data for any other purposes than to assist us in this effort. We bind them contractually, require them to act consistently with this Policy and to use appropriate security measures to protect your personal data.</p>
                    <p>
                    </p><h4>Control of your Personal Information</h4>
                    Except as otherwise described in this statement, personal information you provide to Crystal Springs Resort's website will not be shared outside of the company without your permission.<p></p>
                    <p>
                    </p><h4>Protection of Children's Personal Information</h4>
                    CrystalGolfResort.com is a general audience site and does not knowingly collect any personal information from children.<p></p>
                    <p>
                    </p><h4>Enforcement of this Privacy Statement</h4>
                    If you have questions regarding your privacy on this website, you should contact Resort Marketing at (973) 827.2222.<p></p>

                    <br /><Link  to={privacyPolicyPDF} target="_blank" download><button type="button" className="btn mt-1" style={{backgroundColor:'#7B835E', color:'#fff'}}>Download</button></Link>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy