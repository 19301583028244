import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authApi } from "../constants/auth.api";
import { withoutTokenPost } from "../services/common.service";

export const login = createAsyncThunk("login_user", (data) => {
    const response = withoutTokenPost(authApi.LOGIN, data);
    return response;
});

const initialState = {
    status: null,
    data: null,
};

export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        logout: (state) => {
            state.status = null;
            state.data = null;
        }
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.status = "pending";
        },
        [login.fulfilled]: (state, action) => {
            state.status = true;
            state.data = action.payload;
        },
        [login.rejected]: (state, action) => {
        },
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
