import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { rewardApi } from "../constants/reward.api";
import { withoutTokenPost, withTokenGet } from "../services/common.service";

export const sendMessage = createAsyncThunk("sendMessage", (data) => {
    const response = withoutTokenPost(rewardApi.SEND_MESSAGE,data);
    return response;
});

const initialState = {
    status: null,
    data: null,
};

export const rewardSlice = createSlice({
    name: "rewardSlice",
    initialState,
    reducers: {},
    extraReducers: {
        [sendMessage.fulfilled]: (state, action) => {
            state.status = true;
            state.data = action.payload;
        },
    },
});

export default rewardSlice.reducer;
