import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";
import {
  instructionApi
} from "../constants/instruction.api";
import {
  withoutTokenPost
} from "../services/common.service";

export const GetInstructionByFormName = createAsyncThunk("get_instruction_by_form_name", (data) => {
  const response = withoutTokenPost(
    instructionApi.GET_INSTRUCTION_BY_FORM_NAME,
    data
  );
  return response;
});

const initialState = {
 introInfo:null,
 teeSheetInfo:null,
 mobileSubmissionInfo:null,
 golferInfo:null
};

export const instructionSlice = createSlice({
  name: "instructionSlice",
  initialState,
  reducers: {
   
  },
  extraReducers: {
    [GetInstructionByFormName.fulfilled]: (state, action) => {
      switch(action.payload?.data.formName){
        case "Intro.Page":
          state.introInfo = action.payload.data.instructions;
          break;
        case "Golfer.TeeSheet":
          state.teeSheetInfo = action.payload.data.instructions;
          break;
        case "Mobile.Submission":
          state.mobileSubmissionInfo = action.payload.data.instructions;
          break;
        case "Golfer.Form":
          state.golferInfo = action.payload.data.instructions;
          break;
        default:
          break;
      }
    },
  },
});

export default instructionSlice.reducer;