import React from 'react'
import { useNavigate } from 'react-router-dom';

const Stats = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="sec sec-golf">
        <div className="container">
          <div className="golf-top">
            <h2><img src="images/ui_receipt_icon.png" alt="" /> Reports</h2>

          </div>
          <div className="user-management">
            <div className="table-responsive">
              <table className="table-bordered">
                <thead>
                  <tr>
                    <td>Stats By Course</td>
                    <td>Stats By Starter</td>
                    <td>Stats By Starter</td>
                    <td>Golfers Activity</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Jessica Den</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Mellissa Parker</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Jessica Den</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Mellissa Parker</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Jessica Den</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>Golf Course1</td>
                    <td>Mellissa Parker</td>
                    <td>22/01/2022</td>
                    <td>Active</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="menu-box-col text-center">
              <button type="button" className="btn custom-btn back-btn" onClick={() => navigate(-1)}><i className="fal fa-long-arrow-left"></i> BACK</button>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Stats