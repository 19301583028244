export const teeApi = {
  //apis
  GET_TEE_TIME_LIST_BY_COURSE_ID: "TeeTimeSlot/GetTeeTimeListByCourseId",
  GET_TEE_TIME_LIST_BY_COURSE_ID_V1: "TeeTimeSlot/GetTeeTimeSlotByCourseIdV2",
  GET_SLOT_AVAILABILITY_BY_SLOT_NO:"TeeTimeSlot/GetSlotAvailabilityBySlotNo",
  GET_ENCRYPTED_LINK:"TeeTimeSlot/GetEncryptedUniqueLink",
  GET_DECRYPTED_LINK:"TeeTimeSlot/GetDecryptedUniqueLink",
  ADD_BOOKING_SLOT:"TeeTimeSlot/Add_BookingSlot"

  //another apis here
};
