import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Section from './Section'
import { GetStarterSheet } from "../../slices/starterSlice";
import { getCourseDetail } from "../../slices/courseSlice";
import Loader from "../../common/Loader/Loader";
import { clear, GetEncryptedUniqueLink } from "../../slices/TeeSlice";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';



const StarterSheet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const starter = useSelector((state) => state.starter);
    const auth = useSelector((state) => state.auth)

    const { selected_date, selected_course } = starter;

    const link_sent_data = useSelector((state) => state.tee.link_sent_data);


    useEffect(() => {
        if (auth.data === null) {
            navigate('/login')
        }
        if (starter.selected_course === null) {
            navigate('/login')
        }

        if (starter.selected_course) {
            let data;
            data = auth.data.data.courseName?.filter(item => item.locationId == starter.selected_course)[0]
            data = {
                CourseId: data.locationId,
                TeeTimeDatetime: String(starter.selected_date),
                CourseCode: data.courseCode
            }
            dispatch(GetStarterSheet(data));
        }
    }, [selected_date, selected_course])

    const resendData = (data) => {
        let dataTosend = {
            PosId: data.posId,
            CourseId: data.courseId,
            ConfirmationId: data.confirmationId,
            SlotNumber: data.slotNumber,
            TeeTimeDatetime: data.teeTimeDatetime,
            TicketId: data.ticketId,
            CaptainName: data.captainName,
            IsCaptain: data.isCaptain,
            PhoneNumber: data.mobilePhone,
            IsResend: true,
            UserId: auth.data.data.userId

        };
        dispatch(GetEncryptedUniqueLink(dataTosend));
    };

    useEffect(() => {
        if (link_sent_data?.statusCode === 200) {
            toast("Success");
            dispatch(clear());
        }
    }, [link_sent_data])

    return (
        <>
            <div className="mid-btn starter-top-header">
                <div className="container starter-header">
                    {auth?.data?.data?.roleName === "Admin" && <h6 className="justify-content-center align-content-center m-auto" style={{ "cursor": "pointer" }} onClick={() => navigate(-1)}>Back to menu</h6>}
                    <div className="justify-content-center" style={{ width: 'inherit' }}>

                        <a href="" className="btn custom-btn" data-bs-toggle="modal" data-bs-target="#LegendModal">Legend</a>
                        <a href="" className="btn custom-btn" data-bs-toggle="modal" data-bs-target="#StatsModal">Daily Stats</a>
                    </div>
                </div>
            </div>


            {starter.status &&
                <section className="sec sec-golf">
                    <div className="container">
                        {starter.status === "pending" && <div className="container d-flex justify-content-center align-items-center"><Loader /></div>}
                        {starter.status && starter?.data?.data === null && <p className="text-center">{starter.data?.msg}</p>}
                        {starter.status && starter?.data?.data && starter.data?.data?.map((item, index) => { return <div key={item.teeTimeDateTime + index}> <Section data={item} resendData={resendData} /> </div> })}
                    </div>
                </section>}

            {/* <!-- Modal --> */}
            <div className="modal fade" id="LegendModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Legend</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table ">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="color-btn1 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Waiver signed</h6>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="color-btn2 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Checked in, required fields completed, but waiver not signed</h6>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="color-btn3 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Checked in, required fields not completed, waiver not signed</h6>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="color-btn4 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Not checked in, just booked</h6>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="color-btn5 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Not booked</h6>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="color-btn6 model-1" >

                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center color-btn-heading">
                                                <h6>Starter override</h6>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <div className="color-box">
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn1" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="waiver signed"><ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn2" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="checked in, required fields completed, but waiver not signed">
                                        <ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn3" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="checked in, required fields not completed, waiver not signed">
                                        <ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn4" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="not checked in, just booked">
                                        <ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn5" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="not booked">
                                        <ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn6" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="Starter override">
                                        <ReactTooltip place="top" />
                                    </span>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="StatsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Daily Stats</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        </div>
                        <div className="modal-body">
                            <div className="color-box">
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn12" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="waiver signed">
                                        {starter.status && starter?.data?.totalGreen}<ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn22" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="checked in, required fields completed, but waiver not signed">
                                        {starter.status && starter?.data?.totalYellow}<ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn32" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="checked in, required fields not completed, waiver not signed">
                                        {starter.status && starter?.data?.totalRed}<ReactTooltip place="top" />
                                    </span>
                                </div>
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn42" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="not checked in, just booked">
                                        {starter.status && starter?.data?.totalWhite}<ReactTooltip place="top" />
                                    </span>
                                </div>
                                {/* <div className="color-box-col">
                                    <span className="color-box-in color-btn52" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="not booked">
                                        {starter.status && starter?.data?.totalGrey}<ReactTooltip place="top" />
                                    </span>
                                </div> */}
                                <div className="color-box-col">
                                    <span className="color-box-in color-btn62" data-bs-toggle="tooltip" data-bs-placement="top" data-tip="Starter override">
                                        {starter.status && starter?.data?.totalBrown}<ReactTooltip place="top" />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default StarterSheet