
import React, { useState, useEffect } from "react";
import rewards from './Rewards.json'
import InputMask from 'react-input-mask';
import { sendMessage } from "../../slices/rewardSlice";
import { useDispatch, useSelector } from "react-redux";

const MyRewards = () => {
    const dispatch = useDispatch();
    const [mobilenumber, setMobileNumber] = useState();
    const [error, setError] = useState('');
    const [modal, setModal] = useState(false);
    const [notification, setNotification] = useState(false);
    const [index, setIndex] = useState('');
    const [response, setResponse] = useState('');
    const dataresponse = useSelector((state) => state.reward.data);

    useEffect(() => {
        if (dataresponse) {
            setNotification(true);
            setResponse(dataresponse);
        } else {
            setResponse('');
        }
    }, [dataresponse]);

    const handleChange = (e) => {
        setMobileNumber(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            let obj = {
                PhoneNumber: mobilenumber.replace(/[^\d]/g, '')
            }
            dispatch(sendMessage(obj));
        }
    }

    function closeNotification() {
        setNotification(false);
    }

    function openModal(index) {
        document.body.style.overflow = "hidden";
        setModal(true);
        setIndex(index);

    }

    function CloseModal() {
        setModal(false);
        setIndex('');
        document.body.style.overflow = "scroll";
    }

    const validate = () => {
        let input = mobilenumber.replace(/[^\d]/g, '');
        setError('');
        let isValid = true;
        if (!input) {
            isValid = false;
            setError('Please enter your Mobile Number');
            setNotification(false);
        }
        if (typeof input !== "undefined") {
            var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
            if (!pattern.test(input)) {
                isValid = false;
                setError('Please Enter Number Only');
                setNotification(false);
            } else if (input.length != 10) {
                isValid = false;
                setError('Please enter valid  Mobile Number');
                setNotification(false);

            }
        }
        return isValid;
    }

    return (
        <>
            <div className="reward_container">
                <div className="bodyContainer">
                    <div className="logoContainer">
                        <img src='../images/RESORTREWARDS.png'></img>
                    </div>
                    <div className="bodyContainer_inner">
                        {notification ? (
                            <>
                            {response?.responseCode == 'Failed' &&
                                    <div className="ResponseMessages">
                                        <div className="alert alert-danger fade in text-center server-error-message">
                                            <a  className="close1 removelink" data-dismiss="alert" onClick={closeNotification}>×</a>
                                            <strong>SMS Message Not Sent. Please check your phone number and try again.</strong>
                                        </div>
                                    </div>
                                }
                                {response?.responseCode == 'Success' &&
                                    <div className="ResponseMessages">
                                        <div className="alert alert-success fade in text-center server-success-message">
                                            <a  className="close1 removelink" data-dismiss="alert" onClick={closeNotification}>×</a>
                                            <strong>Please check your text messages for a link to view your points and eligibility for Resort Rewards.</strong>
                                        </div>
                                    </div>
                                }
                            </>) : (
                            <h4 className="mainHeading"> My Points & Rewards</h4>
                        )
                        }
                        <p className="description"> A schedule of Resort Rewards is below, but to view your personal Point Balance and Rewards, please enter
                            your mobile number and click the link you receive as a text message on your phone:</p>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="center-align">
                                <p className="fontBold text_wrapper">Your Mobile Number</p>
                                <div className="input_wrapper">
                                    <div className="input_wrapper_container">
                                        <InputMask
                                            className="input_field"
                                            mask='999-999-9999'
                                            value={mobilenumber}
                                            onChange={(e) => handleChange(e)}>
                                        </InputMask>
                                        {error &&
                                            <p className="validation-error text_wrapper_error">Please enter a valid  Mobile Number</p>
                                        }
                                    </div>
                                    <input type="submit" className="btn1" value="Check Point Balance" />
                                </div>
                            </div>
                            <div>
                                <div className="fontBold rewards_title">Rewards:</div>
                                <div className="container_reward">
                                    {rewards?.rewards?.map((item, idx) => {
                                        return (
                                            <div className="row_reward">
                                                <div className="leftHalf"><a onClick={() => openModal(idx)}> {item?.name}</a></div>
                                                {idx == index && modal &&
                                                    <div className="modal fade in" onClick={CloseModal} id="exampleModal" style={{ display: 'block' }}
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalLabel" aria-hidden="false" >
                                                        <div className="modal-dialog modal-dialog-centered center_modal" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header modal_header_container">
                                                                    <h4 className="modal-title modal-title-container modal_title_container" id="exampleModalLabel">{item?.name} Reward Description is below :</h4>
                                                                    <button type="button" className="close1" onClick={CloseModal} data-dismiss="modal">×</button>
                                                                </div>
                                                                <div className="modal-body modal_body_container">
                                                                    <p className="body_description">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={CloseModal}>Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="rightHalf">{item?.points}</div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer_reward">
                        <div className="threeEqual"><a href="https://csloyalty.com/">Program Rules</a></div>
                        <div className="threeEqual"><a href="/Home/MyRewards">My Points & Rewards</a></div>
                        <div className="threeEqual"><a href="https://csloyalty.com/Home/Questions">Questions?</a></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyRewards;
