import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AddOverrideDetails, clear } from '../../slices/starterSlice';
import { toast } from 'react-toastify';


const Override = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const override_detail = useSelector((state) => state.starter.override_detail);

    const {
        register,
        handleSubmit,
        formState: { errors }, setValue
    } = useForm();
    let { color, slotNumber, golferId, courseId, teeTimeDatetime, membershipType, membershipNumber, posId, teeTimeId, confirmationId, pointBalanceWithAstericks, isCaptain, paidAmount, pointsEarning, pointBalance, firstName, lastName, mobilePhone, email, zipCode, ticketId } = location.state;


    const onSubmit = (data) => {

        let bookingSlotData =
        {
            IsPrivacyPolicy: true,
            IsMarketingPolicy: true,
            GolferId: golferId,
            CourseId: courseId,
            UserId: auth.data.data.userId,
            TicketId: ticketId,
            CartWaiverDetails: {
                Descriptions: data.comments,
                IsCartWaiver: data.check1
            }
        };

        dispatch(AddOverrideDetails(bookingSlotData));
    };

    useEffect(() => {
        if (override_detail?.statusCode === 200) {
            toast(override_detail?.msg);
            dispatch(clear());
            navigate(-1);
        }


    }, [override_detail])


    return (
        <>

            <section className="sec sec-golf">
                <div className="container">
                    <div className="golf-top">
                        <h2>Override</h2>

                    </div>
                    <div className="golfer-box">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="golfer-form-col">
                                        <ul>
                                            <li className="golfer-check">
                                                <input type="checkbox" id="golfer-check1"
                                                    {...register("check1", { required: false })}
                                                />

                                                <label htmlFor="golfer-check1">Manual signature received<br />
                                                    {errors.check1 && errors.check1.type === "required" && (
                                                        <span className="text-danger">
                                                            <b>Must accept Cart Waiver.</b>
                                                        </span>
                                                    )}</label>
                                            </li>
                                            {/* <li className="golfer-check">
                                                <input type="checkbox" id="golfer-check2"
                                                    {...register("check2", { required: true })}
                                                />

                                                <label htmlFor="golfer-check2">I agree to the privacy policies * <br />
                                                    {errors.check2 && errors.check2.type === "required" && (
                                                        <span className="text-danger">
                                                            <b>Must accept Cart Waiver.</b>
                                                        </span>
                                                    )}</label>

                                            </li> */}
                                            {/* <li className="golfer-check">
                                                <input type="checkbox" id="golfer-check3"
                                                    {...register("check3", { required: false })} />

                                                <label htmlFor="golfer-check3">Opt into marketing communication <br />
                                                    {errors.check3 && errors.check3.type === "required" && (
                                                        <span className="text-danger">
                                                            <b>Must accept Cart Waiver.</b>
                                                        </span>
                                                    )}</label>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="golfer-form-col">
                                        <textarea placeholder="Enter Comments*" className="m-0"
                                            {...register("comments", {
                                                required: {
                                                    value: true,
                                                    message: "Must enter valid comment.",
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "Must enter valid comment.",
                                                },
                                            })} />
                                        {errors.comments && (
                                            <span className="text-danger">
                                                <b>{errors.comments.message}</b>
                                            </span>
                                        )}

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="golfer-form-col text-center">
                                        <button type="submit" className="btn custom-btn">SUBMIT <i className="fal fa-long-arrow-right"></i> </button>
                                        <br /> <a type="button" className="mt-4 text-center" onClick={() => navigate(-1)}><i className="fal fa-long-arrow-left"></i> CANCEL</a>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Override