import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { courseApi } from "../constants/course.api";
import { withoutTokenPost } from "../services/common.service";

export const getCourseDetail = createAsyncThunk(
  "course/coursedetail",
  async (data) => {
    const response = await withoutTokenPost(courseApi.GET_COURSE_DETAIL, data);
    return response;
  }
);

const initialState = {
  courseDetail: null,
  status: null,
};

export const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    
  },

  extraReducers: {
    [getCourseDetail.pending]: (state, action) => {
      state.status = "pending";
    },
    [getCourseDetail.fulfilled]: (state, action) => {
      state.courseDetail = action.payload;
      state.status = "fulfilled";
    },
    [getCourseDetail.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default courseSlice.reducer;
