import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const SlotConfirm = () => {
  const link_sent_data = useSelector((state) => state.tee.link_sent_data);
  const msg = link_sent_data?.msg.split("!! ");
  const slot_detail = msg && msg[0].split(':\n');
  console.log(`${window.location.origin}/cart-waiver-form?uniqueLink=${link_sent_data?.data.urlData}`);
  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());

    document.addEventListener('keydown', (e) => {
      e = e || window.event;
      if (e.keyCode === 116 || (e.ctrlKey && e.keyCode === 82)) {
        e.preventDefault();
      }
    });

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, [])

  return (
    <>
      <section className="sec">
        <div className="container">
          <div className="confirmation-box gf-box" style={{ textAlign: 'left' }}>
            <div className="confirmation-icon">
              {/* <img src="images/confirmation-icon-gf.png" alt="" /> */}
            </div>
            <div className="confirmation-n" style={{
              whiteSpace: 'pre-line'
            }}>{slot_detail && slot_detail[0]}:
              <br />
              <p className="mx-5 my-4">{slot_detail && slot_detail[1]}</p>
            </div>

            {msg && msg[1] && <p className="confirmation-text1 text-danger">
              {msg[1]}
            </p>}
          </div>


          {link_sent_data && link_sent_data?.data.msConfirmationFooterText && <p className="gf-center">
            {link_sent_data?.data.msConfirmationFooterText}
          </p>}

        </div>
      </section>
    </>
  );
}

export default SlotConfirm