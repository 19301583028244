import { configureStore } from "@reduxjs/toolkit";
import * as slice from "../slices";

export const store = configureStore({
  reducer: {
    golferSlice: slice.golferSlice,
    courseSlice: slice.courseSlice,
    instruction: slice.instrctionSlice,
    tee: slice.teeSlice,
    auth: slice.authSlice,
    starter: slice.starterSlice,
    user: slice.userSlice,
    reward:slice.rewardSlice
  },
});
