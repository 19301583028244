import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from "react-redux";
import { clearAllData, setSelectedCourse, setSelectedDate } from '../../slices/starterSlice';
import { logout } from '../../slices/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = ({ course }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;


  if (!course.data) return <></>

  var courseCode;
  if (course) {
    courseCode = course?.data.courseCode;
  }
  else {
    return <></>;
  }
  var imgName;
  switch (courseCode) {
    case 'BO':
      imgName = "Ballyowen-Golf-Course-Logo-Black.jpg"
      break;
    case 'BB':
      imgName = "Black-Bear-Golf-Course-Logo-Black.jpg"
      break;
    case 'CC':
      imgName = "Cascades-Golf-Course-Logo-Black.jpg"
      break;
    case 'CS':
      if (path == "/login" || path == "/starter-sheet") {
        imgName = "Crystal-Springs-Resort-Logo-20211.jpg"
      } else {

        imgName = "Crystal-Springs-Resort-Logo-2021.png"
      }

      break;
    case 'MN':
      imgName = "Minerals-Golf-Club-Logo-Black.jpg"
      break;
    case 'WT':
      imgName = "Wild-Turkey-logo.png"
      break;
    default:
      imgName = "";
      break;

  }

  const auth = useSelector((state) => state.auth);
  const auth_data = useSelector((state) => state.auth.data);
  const starter = useSelector((state) => state.starter);

  // const current_date = new Date();
  // const current_date = "2021-05-09";

  useEffect(() => {
    auth_data?.data?.courseName && dispatch(setSelectedCourse(auth_data?.data.courseName[0].locationId.toString()));
    starter.selected_date === null && dispatch(setSelectedDate(moment(new Date()).format("YYYY-MM-DD")));
  }, [auth_data])

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearAllData());

    navigate('/login');
  }

  return (
    <> {
      auth.status === true && auth?.data?.data !== null && auth?.data?.statusCode === 200 ? <>
        <header className="top-header">
          <div className="container text-center af-login">
            <span><a>Welcome {auth_data?.data.firstName}</a></span>
            <span className="select-cor">
              <select onChange={(e) => dispatch(setSelectedCourse(e.target.value))}>
                {auth_data?.data.courseName.map(course => <option key={course.locationId.toString()} value={course.locationId.toString()}>{course.courseName}</option>)}
              </select>
            </span>

            <img src={`${process.env.PUBLIC_URL}/images/${imgName}`} height={80} alt="" />

            <span className="select-date">
              <input type="date" defaultValue={moment(new Date()).format("YYYY-MM-DD")} placeholder="date" onChange={(e) => { dispatch(setSelectedDate(moment.utc(new Date(e.target.value)).format("YYYY-MM-DD"))) }} disabled={auth_data?.data?.roleName === "StarterUpdate" ? true : false} />
            </span>

            <a className="log-out-btn" onClick={handleLogout}><span>Logout</span> <i className="far fa-sign-out"></i></a>
          </div>
        </header>
      </> :
        <>
          <header className="top-header">
            <div className="container text-center">

              <img src={`${process.env.PUBLIC_URL}/images/${imgName}`} height={80} alt="" />
            </div>
          </header>
        </>
    }</>

  );
}

export default Header