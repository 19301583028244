import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { golferApi } from "../constants/golfer.api";
import { withoutTokenPost } from "../services/common.service";

export const EditGolferDetails = createAsyncThunk("edit_golfer_details", (data) => {
  const response = withoutTokenPost(golferApi.UPDATE_DETAILS, data);
  return response;
});

const initialState = {
  status: null,
  data: null
};

export const userSlice = createSlice({
  name: "golferSlice",
  initialState,
  reducers: {
    setData: (state) => {
      state.status = null;
      state.data = null;
    }
  },
  extraReducers: {
    [EditGolferDetails.pending]: (state, action) => {
     },
    [EditGolferDetails.fulfilled]: (state, action) => {
      state.status = true;
      state.data = action.payload;
    },
    [EditGolferDetails.rejected]: (state, action) => {
    },
  },
});

export const { setData } = userSlice.actions;

export default userSlice.reducer;
