import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetail } from "../../slices/courseSlice";
import { GetInstructionByFormName } from "../../slices/instructionSlice";
import Loader from '../../common/Loader/Loader';

const Home = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const course_detail = useSelector((state) => state.courseSlice);
  const instruction = useSelector((state) => state.instruction.introInfo);

  const formName = {
    FormName: "Intro.Page",
  };

  const data = {
    // CourseId: 2,
    CourseId: parseInt(new URLSearchParams(search).get('courseid')) || 2
  };

  useEffect(() => {

    if (!instruction) {
      dispatch(GetInstructionByFormName(formName));
    }
    if (!course_detail.courseDetail) {
      dispatch(getCourseDetail(data));
    }
  }, []);

  document.title = course_detail.courseDetail ? course_detail.courseDetail.data.courseName : "loading..."

  return (
    <>
      <section className="sec-home">
        <div className="container">
          <div className="sec-home-container">
            <div className="home-col">
              {course_detail?.status === "pending" && <Loader />}
              {course_detail?.status === "rejected" && <h1 className="text-center">No Data Found</h1>}
              {course_detail?.status === "fulfilled" && <>
                <h3>WELCOME TO</h3>
                <h2>{course_detail.courseDetail.data.courseName}</h2>
                {instruction && <p>
                  {instruction}
                </p>}

                <Link to="/golf-tee-sheet">
                  <button className="btn custom-btn">
                    Get Started <i className="fal fa-long-arrow-right"></i>
                  </button>
                </Link>
              </>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
