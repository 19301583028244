import React from 'react'
import { Link } from 'react-router-dom';
const cartWaiver = require('../../public/pdf/Golf_Cart_Waiver_Rent_Aggrement.pdf')


const GolfCartWaiver = () => {

    return (
        <>
            <section className="sec">
                <div className="container ">
             <div className="rent_aggrement">
                <h2
      style={{
        paddingTop: 4+'pt',
        textIndent: '0pt',
        textAlign: 'center'
      }}
    >
      GOLF CART RENTAL AGREEMENT
    </h2>
    <h1
      style={{
        paddingTop: 9+'pt',
        textIndent: '0pt',
        lineHeight: '91%',
        textAlign: 'center',
      }}
    >
      RELEASE OF LIABILITY, WAIVER OF CLAIMS AND ASSUMPTION OF RISKS AGREEMENT
    </h1>
     
    <p
      className="s1"
      style={{
      textIndent: '0pt',
      textAlign: 'center'}}
    >
      BY SIGNING THIS DOCUMENT YOU WILL WAIVE CERTAIN LEGAL RIGHTS, INCLUDING
      THE RIGHT TO SUE OR CLAIM COMPENSATION IN THE EVENT OF AN ACCIDENT
    </p>
    <p style={{textIndent: '0pt', 
    textAlign: 'left'}}><br /></p>
    <p
      className="s1"
      style={{
         textIndent: 0+'pt', textAlign: 'center'}}
    >
      PLEASE READ CAREFULLY
    </p>
    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <h3 style={{paddingLeft: 6+'pt', textIndent: '0pt',textAlign: 'justify'}}>
      THIS AGREEMENT
      <span className="p"
        >is made between Minerals Resort &amp; Spa, Inc., Minerals Resort &amp;
        Spa OT, LLC, Wild Turkey Golf Club OT, LLC, their representatives,
        officers, directors, members, affiliated companies, agents, employees
        and assigns (collectively referred to as “the RESORT” or “RESORT”) AND
        the undersigned (referred to as “I”)
        </span>
      
    </h3>
 <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <table
      style={{borderCollapse: 'collapse', marginLeft: 7.044+'pt',width:'100%'}}
      cellspacing="0"
    >
      <tr style={{height: 18+'pt'}}>
        <td
          style={{
            width: 59+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
        >
          <p
            className="s2"
            style={{
              paddingTop: 7+'pt',
              paddingLeft: 5+'pt',
              textIndent: 0+'pt',
              lineHeight: 8+'pt',
              textAlign: 'left'
            }}
          >
            Name
          </p>
        </td>
        <td
          style={{
            width: 148+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
        >
          <p
            className="s3"
            style={{
              paddingTop: 7+'pt',
              paddingLeft: 6+'pt',
              textIndent: 0+'pt',
              lineHeight: 8+'pt',
              textAlign: 'left'
            }}
          >
            Last
          </p>
        </td>
        <td
          style={{
            width: 194+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
        >
          <p
            className="s3"
            style={{
                paddingTop: 7+'pt',
                paddingLeft: 6+'pt',
                textIndent: '0pt',
                lineHeight: 8+'pt',
                textAlign: 'left'
              }}
          
          >
            First
          </p>
        </td>
        <td
          style={{
            width: 63+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
          
        >
          <p
            className="s3"
            style={{
             
                paddingLeft: 5+'pt',
                paddingRight: 9+'pt',
                textIndent: '0pt',
                lineHeight: 8+'pt',
                textAlign: 'left'
              }}
           
          >
            Middle Initial
          </p>
        </td>
      </tr>
      <tr style={{height: 18+'pt'}}>
        <td
          style={{
            width: 59+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
       
          rowSpan="2"
        >
          <p  style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          <p
            className="s2"
            style={{paddingLeft: 5+'pt',textIndent: '0pt', textAlign: 'left'}}
         
          >
            Address
          </p>
        </td>
        <td
          style={{
            width: 405+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
       
          colSpan="3"
        >
          <p  style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          <p
            className="s3"
            style={{
             
                paddingLeft: 6+'pt',
                textIndent: '0pt',
                lineHeight: 7+'pt',
                textAlign: 'left'
              }}
           
          >
            Street
          </p>
        </td>
      </tr>
      <tr style={{height: 20+'pt'}}>
        <td
          style={{
            width: 148+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
        
        >
          <p  style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          <p
            className="s3"
            style={{
             
                paddingLeft: 6+'pt',
                textIndent: '0pt',
                lineHeight: 8+'pt',
                textAlign: 'left'
              }}>
           
            City
          </p>
        </td>
        <td
          style={{
            width: 194+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
       
        >
          <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          <p
            className="s3"
            style={{
             
                paddingLeft: 7+'pt',
                textIndent: '0pt',
                lineHeight: 8+'pt',
                textAlign: 'left'
              }}
         
          >
            State
          </p>
        </td>
        <td
          style={{
            width: 63+'pt',
            borderTopStyle: 'solid',
            borderTopWidth: 1+'pt',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1+'pt',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1+'pt',
            borderRightStyle: 'solid',
            borderRightWidth: 1+'pt'
          }}
         
        >
          <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          <p
            className="s3"
            style={{
             
                paddingLeft: 7+'pt',
                textIndent: '0pt',
                lineHeight: 8+'pt',
                textAlign: 'left'
              }}>
            Zip Code
          </p>
        </td>
      </tr>
    </table>
  
  




    <p style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'justify'}}>
      agree as follows:
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <h3
         style={{
            paddingTop: 7+'pt',
            paddingLeft: 17+'pt',
            textIndent: -11+'pt',
            textAlign: 'justify'
          }}
         
        >
          RENTAL AGREEMENT and PROMISE TO OPERATE GOLF CART IN ACCORDANCE WITH
          POSTED RULES
        </h3>
        <h3 style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'justify'}}>
          AND REGULATIONS.
          <span className="p"
            >I am at least 18 years of age and have presented a copy of my valid
            driver’s license to induce the Resort to allow me to use a golf cart
            on the Resort’s premises. My use of the golf cart is subject to the
            posted rules and regulations as well as the verbal instructions
            provided to me by Resort employees. I agree to operate the golf cart
            in a safe manner and to control the speed and direction of the golf
            cart to protect myself, any occupants of the golf cart and to
            protect other persons and property from damage or injury. I have
            presented to the Resort proof of my possession of a valid insurance
            policy which covers claims arising out of my rental and use of a
            golf cart with policy limits of at least $500,000. I have presented
            valid payment to the Resort for the rental charges for the golf cart
            and represent that I shall return the golf cart to Resort in the
            same condition as it was when I first received it.</span>
          
        </h3>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
      </li>
      <li data-list-text="2.">
        <h3 style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'justify'}}>
          EXPRESS ASSUMPTION OF RISKS-Warnings of Conditions<span className="p"
            >. I acknowledge that prior to accepting the golf cart from the
            resort that I have inspected the golf cart for damage and ensured
            that it is operational. I have tried the brakes and examined the
            tires of the golf cart. After the inspection, I agreed and accepted
            the golf cart from Resort. I represent to the Resort that I know how
            to operate the golf cart and I acknowledge that I have asked the
            Resort for instructions on the use of the golf cart if I had any
            questions or concerns about how to operate the golf cart or if I had
            concerns about the condition of the golf cart. I understand that if
            I do not operate the golf cart in a safe manner, the golf cart could
            tip over, persons could fall out of the golf cart and collisions
            with other persons or things could occur. I recognize that I will be
            in full control as the driver of the golf cart and that the safety
            of other occupants of the golf cart are my primary responsibility as
            driver. I understand that occupants should be seated at all times
            and that SPEEDING, JOY RIDING, DISREGARDING TRAFFIC/SPEED SIGNS, OR
            ANY TYPE OF RECKLESS DRIVING ACTIVITY WITH THE GOLF CART IS
            PROHIBITED. In addition, GOLF CARTS MAY ONLY BE OPERATED ON CART
            PATHS WHEN PLAYING GOLF AND MAY ONLY BE USED ON THE RESORT’S GOLF
            COURSES WHEN ACTUALLY PLAYING GOLF. I ACKNOWLEDGE AND AGREE THAT IN
            THE EVENT MY GOLF CART IS USED IN VIOLATION OF THESE RULES, THE
            RESORT MAY TERMINATE MY USE OF THE CART.</span>
          
        </h3>
        <p
         style={{
            paddingTop: 8+'pt',
            paddingLeft: 6+'pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          I acknowledge that Golf carts are motorized vehicles and driving or
          riding in these vehicles can lead to serious injury, property damage
          and even death. I FREELY ACKNOWLEDGE, ACCEPT AND FULLY ASSUME ALL SUCH
          RISKS, DANGERS AND HAZARDS AND THE POSSIBILITY OF PERSONAL INJURY,
          DEATH, DISEASE, PROPERTY DAMAGE AND LOSS RESULTING THEREFROM.
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
      </li>
      <li data-list-text="3.">
        <h3 style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'justify'}}>
          RELEASE OF LIABILITY<span className="p"
            >. I EXPRESSLY AGREE, as a condition of being allowed to use the
            golf cart that I do freely accept and voluntarily assume ALL RISKS
            of personal injury or death or property damage, and FULLY RELEASE
            the Resort to the greatest extent permitted by law FROM ANY AND ALL
            LIABILITY for personal injury, death, disease or property damage
            arising out of or resulting from my use of the golf cart. I release
            the Resort from claims arising solely or partly out of the Resort’s
            own negligence.</span>
          
        </h3>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
      </li>
      <li data-list-text="4.">
        <h3 style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'justify'}}>
          INDEMNIFICATION<span className="p"
            >. To the fullest extent permitted by law, I agree to DEFEND,
            INDEMNIFY AND HOLD HARMLESS the Resort from any and all claims,
            suits, costs and expenses including attorneys’ fees asserted against
            the Resort by me or third parties arising or allegedly arising out
            of or resulting from my conduct while utilizing the Resort’s
            Premises and in connection with or arising out of my rental or use
            of the golf cart. I agree that the obligation to defend, indemnify
            and hold harmless as described above, specifically includes the
            obligation to defend, indemnify and hold harmless the Resort for its
            own negligence or fault, excepting from the foregoing the sole
            negligence or fault of the Resort if prohibited by law.
            </span>
          
        </h3>
      </li>
      <li data-list-text="5.">
        <h3
          style={{
            paddingTop: 4+'pt',
            paddingLeft: 6+'pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          CHOICE OF VENUE AND LAW<span className="p"
            >. In exchange for, and in consideration of, RESORT making the golf
            cart, the facilities, activities and the premises available to me
            for, I CONTRACTUALLY AGREE that any and all disputes between myself
            and RESORT arising from my participation, and including any claims
            for personal injury and/or death, will be GOVERNED BY THE LAWS OF
            THE STATE OF NEW JERSEY and the EXCLUSIVE JURISDICTION thereof will
            be in the
            </span>
          
        </h3>
      </li>
    </ol>
    <p style={{paddingLeft: 6+'pt', textIndent: '0pt',textAlign: 'justify'}}>
      state courts of THE STATE OF NEW JERSEY or U.S. DISTRICT COURT located in
      the District of New Jersey and venue in the state court shall be in Sussex
      County.
    </p>
    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <p style={{paddingLeft: 6+'pt', textIndent: '0pt', textAlign: 'left'}}>
      I, the undersigned, HAVE READ AND UNDERSTAND the terms of the above and
      the FULL RELEASE FROM LIABILITY AND INDEMNITY AGREEMENT which is an
      essential part of it. I am signing it freely and of my own accord,
      realizing IT IS BINDING upon myself, my heirs and assigns, and, in the
      event that I am signing it on behalf of any minors, that I have full
      authority to do so, realizing its BINDING EFFECT on them as well as
      myself.
    </p>
    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <p style={{paddingLeft: 6+'pt', textIndent: 2+'pt', textAlign: 'left'
    }}>
      If any part of this agreement is deemed unenforceable, the remainder shall
      be an enforceable contract between the parties.
    </p>
    <h3
      style={{
        paddingTop: 9+'pt',
        paddingLeft: 6+'pt',
        textIndent: '0pt',
        textAlign: 'justify'
      }}
    >
      I Agree
    </h3>
    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <p style={{paddingLeft: 6+'pt', textIndent: '0pt',textAlign: 'justify'}}>
      I HAVE CAREFULLY READ, AND I UNDERSTAND AND VOLUNTARILY AGREE TO, THE
      TERMS ABOVE AND THE RESORT’S RULES &amp; REGULATIONS AND PRIVATE GOLF CART
      RULES AND REGULATIONS. MEMBER IS BOUND BY THESE RULES &amp; REGULATIONS
      AND THIS WAIVER AND RELEASE FOR THE ENTIRE GOLF SEASON AND UNTIL DECEMBER
      31, 2023.
    </p>
    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
    <p
      className="s4"
      style={{
        paddingLeft: 6+'pt',
        textIndent: '0pt',
        lineHeight: 1+'pt',
        textAlign: 'left'
      }}
    ></p>
    <p
      style={{
        paddingLeft: 6+'pt',
        textIndent: '0pt',
        lineHeight: 9+'pt',
        textAlign: 'left',
      }}
    >
      NAME AND SIGNATURE
    </p>
    </div>













                    <br /><Link to={cartWaiver} target="_blank" download><button type="button" className="btn mt-1" style={{ backgroundColor: '#7B835E', color: '#fff' }}>Download</button></Link>
                </div>
            </section>
        </>
    );
}

export default GolfCartWaiver