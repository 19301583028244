import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { starterApi } from "../constants/starterSheet.api";
import { withoutTokenPost } from "../services/common.service";

export const GetStarterSheet = createAsyncThunk(
    "get_starter_sheet",
    (data) => {
        const response = withoutTokenPost(
            starterApi.GET_STARTER_SHEET,
            data
        );
        return response;
    }
);
export const AddOverrideDetails = createAsyncThunk(
    "add_override_details",
    (data) => {
        const response = withoutTokenPost(
            starterApi.ADD_OVERRIDE_DETAIL,
            data
        );
        return response;
    }
);

const initialState = {
    status: null,
    data: null,
    selected_course: null,
    selected_date: null,
    override_detail: null,
};

export const starterSlice = createSlice({
    name: "starterSlice",
    initialState,
    reducers: {
        setSelectedCourse: (state, action) => {
            state.selected_course = action.payload
        },

        setSelectedDate: (state, action) => {
            state.selected_date = action.payload
        },

        clear: (state) => {
            state.override_detail = null;
        },
        clearData: (state) => {
            state.status = null;
            state.data = null;
        },
        clearAllData: (state) => {
            state.selected_date = initialState.selected_date;
            state.selected_course = initialState.selected_course;
        }

    },
    extraReducers: {
        [GetStarterSheet.pending]: (state, action) => {
            state.status = "pending";
        },
        [GetStarterSheet.fulfilled]: (state, action) => {
            state.status = true;
            state.data = action.payload;
        },
        [AddOverrideDetails.fulfilled]: (state, action) => {
            state.override_detail = action.payload;
        },

    },
});

export const { setSelectedCourse, setSelectedDate, clear, clearData, clearAllData } = starterSlice.actions;

export default starterSlice.reducer;
