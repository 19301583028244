import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../constants/user.api";
import { withoutTokenPost, withTokenGet } from "../services/common.service";

export const getUserDetails = createAsyncThunk("getUserDetails", () => {
    const response = withTokenGet(userApi.GET_USER_DETAILS);
    return response;
});

const initialState = {
    status: null,
    data: null,
};

export const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {

    },
    extraReducers: {
        [getUserDetails.fulfilled]: (state, action) => {
            state.status = true;
            state.data = action.payload;
        },
    },
});

export default userSlice.reducer;
