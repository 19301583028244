import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../slices/userSlice';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Loader from '../../common/Loader/Loader';


const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [])


  return (
    <>
      <section className="sec sec-golf">
        <div className="container">
          <div className="golf-top">
            <h2>User Management</h2>
          </div>
          {!user.status && <div className="text-center"><Loader /></div>}
          {user.data && <div className="user-management">
            <div className="table-responsive">
              <table className="table-bordered">
                <thead>
                  <tr>
                    <td>Users</td>
                    <td>Last Login</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {user?.data?.data.map((item, index) => {
                    return <tr key={index}>
                      <td>{item.firstName} {item.lastName}</td>
                      {item.lastLoginDt ? <td>{new Date(item.lastLoginDt).toLocaleDateString()} {moment(item.lastLoginDt).format('LT')}</td> : <td></td>}
                      <td>{item.status ? "Active" : ""}</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="menu-box-col text-center">
              <button type="button" className="btn custom-btn back-btn" onClick={() => navigate(-1)}><i className="fal fa-long-arrow-left"></i>BACK</button>
            </div>
          </div>}

        </div>
      </section>
    </>
  )
}

export default UserManagement