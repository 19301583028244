import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { logout } from "../../slices/authSlice";

const AdminMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.status === null || auth.data === null) {
            navigate('/login');
        }
    }, [, auth])

    const back = () => {
        dispatch(logout())
    }

    return (
        <>
            <section className="sec sec-golf">
                <div className="container">
                    <div className="golf-top">
                        <h2>Select from menu</h2>

                    </div>
                    <div className="menu-box">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="menu-box-col">
                                    <Link to="/user-management">
                                        <img src="images/menu-icon1.png" alt="" />
                                        <span>USER MANAGEMENT</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="menu-box-col">
                                    <Link to="/starter-sheet">
                                        <img src="images/menu-icon2.png" alt="" />
                                        <span>STARTER SHEET</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="menu-box-col">
                                    <Link to="/stats">
                                        <img src="images/menu-icon3.png" alt="" />
                                        <span>STATS</span>
                                        <p style={{ color: '#fff' }}>(Coming Soon)</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="menu-box-col">
                                    <Link to="/settings">
                                        <img src="images/menu-icon4.png" alt="" />
                                        <span>SETTINGS</span>
                                        <p style={{ color: '#fff' }}>(Coming Soon)</p>
                                    </Link>
                                </div>
                            </div>

                            {/* <div className="col-md-12">
                                <div className="menu-box-col text-center">
                                    <button type="submit" className="btn custom-btn back-btn" onClick={back}><i className="fal fa-long-arrow-left"></i> BACK</button>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default AdminMenu