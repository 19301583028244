import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setData, EditGolferDetails } from '../../slices/golferSlice';
import { toast } from 'react-toastify';
import { clearData } from '../../slices/starterSlice';

const UpdateGolferInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const golfer_data = useSelector((state) => state.golferSlice);


    const {
        register,
        handleSubmit,
        isDirty,
        formState: { errors }, setValue
    } = useForm();
    let { color, slotNumber, golferId, courseId, teeTimeDatetime, membershipType, membershipNumber, posId, teeTimeId, confirmationId, pointBalanceWithAstericks, isCaptain, paidAmount, pointsEarning, pointBalance, firstName, lastName, mobilePhone, email, zipCode } = location.state;

    // firstName && setValue("firstname", firstName);
    // lastName && setValue("lastname", lastName);
    // mobilePhone && setValue("number", mobilePhone);
    // email && setValue("email", email);
    // zipCode && setValue("zipcode", zipCode);

    const onSubmit = (data) => {
        let golferData = {
            GolferId: golferId,
            FirstName: data.firstname,
            LastName: data.lastname,
            Email: data.email,
            Phone: data.number,
            ZipCode: data.zipcode,
            IsEditByStarter: true,
            UpdatedBy: auth.data.data.userId
        };

        dispatch(EditGolferDetails(golferData));
    };

    function mask(value) {
        if (value?.length) {
            var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            setValue("number", x);
        }
        else {
            setValue("number", "");
        }
    }

    useEffect(() => {
        if (golfer_data?.data?.statusCode === 200) {
            toast(golfer_data?.data?.msg);
            dispatch(clearData())
            dispatch(setData());
            navigate(-1);
        }

    }, [golfer_data])


    return (
        <>
            <section className="sec sec-golf">
                <div className="container">
                    <div className="golf-top">
                        <h2>Update Golfer Information</h2>
                    </div>
                    <div className="golfer-box">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="golfer-form-col">
                                        <input type="text" name="" placeholder="First Name*" className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} defaultValue={firstName}
                                            {...register("firstname", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter full first name.",
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message: "minimum 2 character required",
                                                },
                                                maxLength: {
                                                    value: 26,
                                                    message: "maximum 26 character required",
                                                },
                                                pattern: {
                                                    value: /^[A-Z a-z ,.'-]+$/i,
                                                    message: "Not valid",
                                                },
                                                onChange: (e) => { setValue("firstname", e.target.value); }
                                            })} />
                                        {errors.firstname && errors.firstname && (
                                            <span className="text-danger">
                                                {errors.firstname.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="golfer-form-col">
                                        <input type="text" name="" placeholder="Last Name*" className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                                            defaultValue={lastName}
                                            {...register("lastname", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter full last name.",
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message: "minimum 2 character required",
                                                },
                                                maxLength: {
                                                    value: 26,
                                                    message: "maximum 26 character required",
                                                },
                                                pattern: {
                                                    value: /^[A-Z a-z ,.'-]+$/i,
                                                    message: "Not valid",
                                                },
                                                onChange: (e) => { setValue("lastname", e.target.value); }
                                            })} />
                                        {errors.lastname && errors.lastname && (
                                            <span className="text-danger">
                                                {errors.lastname.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="golfer-form-col">
                                        <input type="text" name="" placeholder="973-555-5555" className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                            defaultValue={mask(mobilePhone)}
                                            {...register("number",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "Please enter mobile number.",
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "minimum 14 character required",
                                                    },
                                                    maxLength: {
                                                        value: 14,
                                                        message: "maximum 14 character required",
                                                    },
                                                    onChange: (e) => mask(e.target.value)
                                                })} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="golfer-form-col">
                                        <input type="text" name="" placeholder="Email*" className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            defaultValue={email}
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter valid email address.",
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                },
                                                onChange: (e) => { setValue("email", e.target.value); }
                                            })} />
                                        {errors.email && errors.email && (
                                            <span className="text-danger">
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="golfer-form-col">
                                        <input type="text" name="" placeholder="Zip Code*" className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                                            defaultValue={zipCode}
                                            {...register("zipcode", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter valid zip code.",
                                                },
                                                minLength: {
                                                    value: 5,
                                                    message: "minimum 5 character required",
                                                },
                                                onChange: (e) => { setValue("zipcode", e.target.value); }
                                            })} />
                                        {errors.zipcode && errors.zipcode && (
                                            <span className="text-danger">
                                                {errors.zipcode.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="golfer-form-col">
                                        <textarea placeholder="Enter Comments*"
                                            {...register("comments", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter comment",
                                                },
                                                minLength: {
                                                    value: 5,
                                                    message: "minimum 5 character required",
                                                },
                                            })} />

                                    </div>
                                </div> */}
                                <div className="col-md-12">
                                    <div className="golfer-form-col text-center">
                                        <button type="submit" className="btn custom-btn mx-2">SUBMIT <i className="fal fa-long-arrow-right"></i> </button>
                                        <br /> <a type="button" className="mt-4 text-center" onClick={() => navigate(-1)}><i className="fal fa-long-arrow-left"></i> CANCEL</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

export default UpdateGolferInfo