import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Header, Footer } from "../../common";
import { getCourseDetail } from "../../slices/courseSlice";
import { login } from "../../slices/authSlice";
import Loader from '../../common/Loader/Loader';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course_detail = useSelector((state) => state.courseSlice);
  const auth = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    let obj = {
      UserName: data.username,
      Password: data.password
    }
    dispatch(login(obj))

  };

  useEffect(() => {
    !course_detail.courseDetail && dispatch(getCourseDetail({ CourseId: 4 }));

    if (auth.status === true && auth?.data?.data !== null && auth.data.statusCode === 200) {
      let role = auth.data.data.roleName;
      if (role === "Admin") {
        navigate('/admin-menu');
      }
      if (role === "StarterUpdate" || role === "CourseManager") {
        navigate('/starter-sheet')
      }

    }
  }, [auth])

  document.title = course_detail.courseDetail ? course_detail.courseDetail.data.courseName : "loading..."

  return (
    <>
      <div className="content">
        <section className="sec">
          <div className="container">
            <div className="form-login">
              <h3>Golf Loyalty Program Staff Log-In</h3>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-login-col">
                  <label htmlFor="">Username</label>
                  <input type="text" className={`form-control ${errors.username ? 'is-invalid' : ''}`} placeholder="User Name"
                    {...register("username", { required: true })} />
                  {errors.username && <span className="text-danger">This field is required</span>}
                </div>

                <div className="form-login-col">
                  <label htmlFor="">Password</label>
                  <input type="password" className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Password"
                    {...register("password", { required: true })} />
                  {errors.password && <span className="text-danger">This field is required</span>}
                </div>

                <div className="d-flex justify-content-center align-items-center mb-2">
                  {auth.status === true && auth.data.data === null && auth.data.statusCode === 400 && <span className="text-danger">{auth.data.msg}</span>}
                  {auth.status === "pending" && <Loader />}
                </div>

                <div className="form-login-col">
                  <button type="submit" className="btn custom-btn">LOGIN <i className="fal fa-long-arrow-right"></i></button>
                </div>

              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
