import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { teeApi } from "../constants/tee.api";
import { withoutTokenPost } from "../services/common.service";

export const GetTeeTimeListByCourseId = createAsyncThunk(
  "get_course_detail",
  (data) => {
    const response = withoutTokenPost(
      teeApi.GET_TEE_TIME_LIST_BY_COURSE_ID_V1,
      data
    );
    return response;
  }
);

export const GetSlotAvailabilityBySlotNo = createAsyncThunk(
  "slot_availability_detail",
  (data) => {
    const response = withoutTokenPost(
      teeApi.GET_SLOT_AVAILABILITY_BY_SLOT_NO,
      data
    );
    return response;
  }
);

export const GetEncryptedUniqueLink = createAsyncThunk(
  "get_encrypted_link",
  (data) => {
    const response = withoutTokenPost(teeApi.GET_ENCRYPTED_LINK, data);
    return response;
  }
);

export const GetDecryptedUniqueLink = createAsyncThunk(
  "get_decrypted_link",
  (data) => {
    const response = withoutTokenPost(teeApi.GET_DECRYPTED_LINK, data);
    return response;
  }
);

export const AddBookingSlot = createAsyncThunk(
  "add_booking_slot",
  (data) => {
    const response = withoutTokenPost(teeApi.ADD_BOOKING_SLOT, data);
    return response;
  }
);

const initialState = {
  timeList: null,
  timeList_data: null,
  status: null,
  slot_available: null,
  link_sent_data: null,
  golferForm: null,
  booking_slot_info: null
};

export const teeSlice = createSlice({
  name: "teeSlice",
  initialState,
  reducers: {
    clear: (state) => {
      state.link_sent_data = null;
    },
    clear_slot_data: (state) => {
      state.slot_available = null;
    }
  },
  extraReducers: {
    [GetTeeTimeListByCourseId.pending]: (state, action) => { },
    [GetTeeTimeListByCourseId.fulfilled]: (state, action) => {
      if (state.timeList_data === null) {
        state.timeList_data = action.payload.data;
        state.timeList = action.payload;
      }
      else {
        if (action.payload?.currentPageIndex >= 0) {
          state.timeList = action.payload;
          if (action.payload?.data) {
            let newdata = state.timeList_data;
            newdata = [...newdata, ...action.payload?.data];
            state.timeList_data = newdata;
          }
        } else {
          if (action.payload?.data) {
            let newdata = state.timeList_data;
            newdata = [...action.payload?.data, ...newdata];
            state.timeList_data = newdata;
          }
        }

      }
      state.status = "fulfilled";
    },
    [GetTeeTimeListByCourseId.rejected]: (state, action) => {
      state.status = "rejected";
    },
    [GetSlotAvailabilityBySlotNo.fulfilled]: (state, action) => {
      if (action.payload?.statusCode === 200 && action.payload?.data === true) {
        state.slot_available = true;
      } else {
        state.slot_available = false;
      }
    },
    [GetEncryptedUniqueLink.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.link_sent_data = action.payload;
      } else {
        state.link_sent_data = false;
      }
    },

    [GetDecryptedUniqueLink.pending]: (state, action) => {
      state.golferForm = "pending"
    },
    [GetDecryptedUniqueLink.fulfilled]: (state, action) => {
      state.golferForm = action.payload;
    },


    [AddBookingSlot.fulfilled]: (state, action) => {
      state.booking_slot_info = action.payload;
    },
  },
});

export default teeSlice.reducer;

export const { clear, clear_slot_data } = teeSlice.actions;