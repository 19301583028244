import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clear, GetEncryptedUniqueLink } from "../../slices/TeeSlice";
import { toast } from 'react-toastify';


const getAstericks = (number) => {
    let stars = "";

    for (let i = 1; i <= number; i++) {
        stars += '*'
    }
    return stars;
}

const getbackgroundColor = (color) => {
    switch (color) {
        case "Gy":
            return "#c1bec0"
            break;
        case "Yw":
            return "#fffeab"
            break;
        case "Rd":
            return "#fc8585"
            break;
        case "Gn":
            return "#ADE19F"
            break;
        case "Bn":
            return "#B19C5F"
            break;
        case "We":
            return "#ffffff"
            break;
        default:
            return ""
            break;
    }
}

function mask(value) {
    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return x;
}

const Section = ({ data, resendData }) => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    // const link_sent_data = useSelector((state) => state.tee.link_sent_data);



    const { slotDetails } = data;
    const {
        register,
        handleSubmit,
        formState: { errors }, setValue
    } = useForm();


    function mask(value) {
        if (!value) {
            return "";
        }
        var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        return x;
    }

    return (
        <>
            <div className="starter-form-sec">
                {slotDetails.map((data, index, array) => {
                    let { color, slotNumber, teeTimeDatetime, membershipType, membershipNumber, pointBalanceWithAstericks, isCaptain, paidAmount, pointsEarning, pointBalance, firstName, lastName, mobilePhone, email, zipCode } = data;

                    const [editable, seteditable] = useState(false);
                    const [resend, setresend] = useState(false);


                    return <div key={index + teeTimeDatetime} className="m-0" style={{ borderBottom: index !== array.length - 1 && "dashed #777777" }}> <div className="starter-form-row" style={{ backgroundColor: getbackgroundColor(color) }} >
                        <div className="starter-form-left">
                            <span className="starter-date"> {moment(teeTimeDatetime).format("LT")}</span>
                            <span className="starter-number"
                                style={{
                                    backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                }}>{slotNumber}</span>

                            <a className={`starter-btn ${color == "Gy" ? "" : "green-btn"}`}
                                style={{
                                    color: color === "Gy" ? "#c1bec0" : ""
                                }}
                                onClick={() => color !== "Gy" && resendData(data)}>RESEND
                                <i className="fal fa-long-arrow-right"></i>
                                {resend && <i className='fa fa-check'></i>}
                            </a>
                            {/* <a className={`starter-btn ${color == "Gy" ? "" : "blue-btn"}`} onClick={() => color !== "Gy" && navigate("/update-golfer-info", { state: data })}>UPDATE <i className="fal fa-long-arrow-right"></i></a>
                            <a className={`starter-btn ${color == "Gy" ? "" : "red-btn"}`} onClick={() => color !== "Gy" && navigate("/override-info", { state: data })}>OVERRIDE <i className="fal fa-long-arrow-right"></i></a> */}


                            {/* <Link to={color === "Gy" ? "" : "/update-golfer-info"} state={data}><p className={`starter-btn ${color == "Gy" ? "" : "blue-btn"}`} style={{
                                color: color === "Gy" ? "#c1bec0" : "",
                                cursor: color === "Gy" ? "default" : "pointer"
                            }}>UPDATE <i className="fal fa-long-arrow-right"></i></p></Link> */}
                           <p className={`starter-btn ${color == "Gy" ? "" : "blue-btn"}`} style={{
                                color: color === "Gy" ? "#c1bec0" : "#385E9B",
                                cursor: color === "Gy" ? "default" : "pointer"
                            }}>UPDATE <i className="fal fa-long-arrow-right"></i></p>
                            <Link to={color === "Gy" ? "" : "/override-info"} state={data}> <p className={`starter-btn ${color == "Gy" ? "" : "red-btn"}`} style={{
                                color: color === "Gy" ? "#c1bec0" : "",
                                cursor: color === "Gy" ? "default" : "pointer"
                            }}>OVERRIDE <i className="fal fa-long-arrow-right"></i></p></Link>
                        </div>
                        <div className="starter-form-right">
                            <div className="starter-form-head">
                                {/* <div className="d-flex flex-lg-wrap"><h4>{membershipType} </h4> <h2 id="asterics">{getAstericks(pointBalanceWithAstericks)}</h2></div> */}

                                <h4 style={{ display: "inherit" }} id="asterics">{membershipType} <span><h2 >{getAstericks(pointBalanceWithAstericks)}</h2></span></h4>
                                <span>{isCaptain ? <img src="images/c-icon.png" alt="" style={{
                                    filter: color === "Gy" ? "grayscale(100%)" : ""
                                }} /> : <>+</>}</span>
                            </div>
                            <ul className="starter-list">
                                <li>{paidAmount && <>${paidAmount} </>} Paid</li>
                                <li>Points Earned Today: {pointsEarning && parseInt(pointsEarning).toLocaleString("en-US")}</li>
                                <li>Point Balance: {pointBalance && parseInt(pointBalance).toLocaleString("en-US")} </li>
                                <li>Member#: {membershipNumber ? membershipNumber : ""}</li>
                            </ul>
                            <div className="starter-form-row-in">
                                <div className="starter-form-col">
                                    <label>First Name</label>
                                    <input
                                        className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}
                                        style={{
                                            backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                        }}
                                        type="text"
                                        // placeholder="First Name"
                                        disabled={!editable}
                                        defaultValue={firstName}
                                        {...register("firstname", {
                                            pattern: {
                                                value: /^[A-Za-z]+$/i,
                                                message: "Not valid",
                                            },
                                            onChange: (e) => { setValue("firstname", e.target.value); }
                                        })}
                                    />
                                    {errors.firstname && errors.firstname && (
                                        <span className="text-danger">
                                            {errors.firstname.message}
                                        </span>
                                    )}
                                </div>
                                <div className="starter-form-col">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                                        style={{
                                            backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                        }}
                                        // placeholder="Last Name"
                                        disabled={!editable}
                                        defaultValue={lastName}
                                        {...register("lastname", {
                                            pattern: {
                                                value: /^[A-Za-z]+$/i,
                                                message: "Not valid",
                                            },
                                            onChange: (e) => { setValue("lastname", e.target.value); }
                                        })}
                                    />
                                    {errors.lastname && errors.lastname && (
                                        <span className="text-danger">
                                            {errors.lastname.message}
                                        </span>
                                    )}
                                </div>
                                <div className="starter-form-col">
                                    <label>Mobile</label>
                                    <input
                                        type="text"
                                        // placeholder="Mobile"
                                        className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                        style={{
                                            backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                        }}
                                        disabled={!editable}
                                        defaultValue={mask(mobilePhone)}
                                        {...register("number",
                                            {
                                                onChange: (e) => setValue("mobile", mask(e.target.value))
                                            })}
                                    />
                                    {errors.number && errors.number && (
                                        <span className="text-danger">
                                            {errors.number.message}
                                        </span>
                                    )}
                                </div>
                                <div className="starter-form-col" id="starter-form-col-email">
                                    <label>Email</label>
                                    <input
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}

                                        style={{
                                            backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                        }}
                                        type="text"
                                        // placeholder="Email"
                                        disabled={!editable}
                                        defaultValue={email}
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: "Please enter valid email address.",
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            },
                                            onChange: (e) => { setValue("email", e.target.value); }
                                        })}
                                    />
                                    {errors.email && errors.email && (
                                        <span className="text-danger">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                                <div className="starter-form-col">
                                    <label>Zip Code</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
                                        style={{
                                            backgroundColor: color === "Gy" ? "#c1bec0" : ""
                                        }}
                                        // placeholder="Zip Code"
                                        disabled={!editable}
                                        defaultValue={zipCode}
                                        {...register("zipcode", {
                                            required: {
                                                value: true,
                                                message: "Please enter valid zip code.",
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "minimum 5 character required",
                                            },
                                            onChange: (e) => { setValue("zipcode", e.target.value); }
                                        })}
                                    />
                                    {errors.zipcode && errors.zipcode && (
                                        <span className="text-danger">
                                            {errors.zipcode.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                })}
            </div>
        </>
    )
}

export default Section