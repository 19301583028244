import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Card from "./Card";
import { GetInstructionByFormName } from "../../slices/instructionSlice";
import { GetTeeTimeListByCourseId } from "../../slices/TeeSlice";
import Modal from "../../common/Modal/Modal";
import Loader from "../../common/Loader/Loader";
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';


const TeeSheet = ({ notifier }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modelData, setmodelData] = useState(null);

  const instruction = useSelector((state) => state.instruction.teeSheetInfo);
  const course_detail = useSelector((state) => state.courseSlice.courseDetail?.data);

  const tee = useSelector((state) => state.tee);
  const slotConfirm = tee.link_sent_data;
  const timeList_data = tee.timeList_data;

  const timeList = tee.timeList;


  const [PageIndex, setPageIndex] = useState(0);
  const [PageNegativeIndex, setNegativePageIndex] = useState(0);


  var course, formName;

  formName = {
    FormName: "Golfer.TeeSheet",
  };

  course = {
    CourseId: course_detail?.locationId,
    TeeTimeDatetime: "2021-05-09T11:48:00",
    CourseCode: course_detail?.courseCode,
    PageIndex: 0
    // CourseId: 18927,
    // TeeTimeDatetime: "2021-05-09T08:48:00",
    // CourseCode: "BB",
    // PageIndex: 0
  };

  useEffect(() => {
    if (!instruction) {
      dispatch(GetInstructionByFormName(formName));
    }
    if (!tee.timeList && PageIndex == 0) {
      dispatch(GetTeeTimeListByCourseId(course));
    }
  }, []);

  useEffect(() => {
    // if (slotConfirm?.statusCode === 200) {
    //   navigate('/slot-confirm');
    // }
    if (slotConfirm?.statusCode === 200 && slotConfirm.data === null) {
      notifier.confirm(
        slotConfirm?.msg,
        false,
        false,
        {
          labels: {
            confirm: 'Alert',
          }
        }
      )
    }
    else if (slotConfirm?.statusCode === 200 && slotConfirm.data) {
      navigate('/slot-confirm');
    }
    else {

    }
  }, [slotConfirm]);

  useEffect(() => {
    course.PageIndex = PageIndex;
    PageIndex > 0 && dispatch(GetTeeTimeListByCourseId(course));
  }, [PageIndex]);

  useEffect(() => {
    course.PageIndex = PageNegativeIndex;
    PageNegativeIndex < 0 && dispatch(GetTeeTimeListByCourseId(course));
  }, [PageNegativeIndex]);

  const current_dateTime = new Date();
  const current_hour = current_dateTime.getHours();
  const current_minute = current_dateTime.getMinutes();

  const geTimeDifference = (time1, time2) => {
    let hour1 = time1.split(":")[0];
    let hour2 = time2.split(":")[0];
    let min1 = time1.split(":")[1];
    let min2 = time2.split(":")[1];

    let diff_hour = hour2 - hour1;
    let diff_min = min2 - min1;
    if (diff_hour < 0) {
      diff_hour += 24;
    }
    if (diff_min < 0) {
      diff_min += 60;
      diff_hour--;
    } else if (diff_min >= 60) {
      diff_min -= 60;
      diff_hour++;
    }
    return [diff_hour, diff_min];
  }


  const scrTo = (id) => {
    if (tee.timeList?.data && id) {
      let myElement = document.getElementById(id);
      let topPos = myElement?.offsetTop;
      window.scrollTo({
        top: topPos - parseInt((40 / 100) * window.innerHeight),
        behavior: 'smooth'
      });
    }
  }

  const nearestTime = (array) => {
    for (let item of array) {
      let timeIndex = item.teeTimeIndex;
      if (timeIndex) {

        let time_diff = geTimeDifference(`${current_hour}:${current_minute}`, timeIndex);

        let hrs = time_diff[0];
        let min = time_diff[1];
        if (hrs >= 0 && min <= current_minute) {
          return item
        }
      }
    }

    return array[0];
  }

  useEffect(() => {
    if (timeList?.data) {

      let obj = nearestTime(timeList?.data)

      scrTo(obj.teeTimeIndex);
    }
  }, [])

  const loadFunc = () => {
    setPageIndex(PageIndex + 1);
  }
  const loadPrev = () => {
    setNegativePageIndex(PageNegativeIndex - 1)
  }


  return (
    <>
      <section className="sec sec-golf">
        <div className="container p-0">


          {instruction &&
            <>
              <div className="golf-top py-4">
                <h2>Select Your Tee Slot <i className="fas fa-info-circle" data-tip={instruction}></i> <ReactTooltip place="bottom" multiline={true} className="w-50" /> </h2>
                {/* <p>{instruction}</p> */}
              </div>
            </>}
          {!tee.timeList && <><div className="sec-home-container">
            <div className="home-col"><Loader /></div></div></>}


          <div className="d-flex justify-content-center align-content-center my-4">
            <button className="btn custom-btn" onClick={loadPrev}>Load earlier tee times</button>
          </div>

          {tee.timeList?.msg === "TeeTime Slot Not Found" && tee.timeList.currentPageIndex == 0 && <>
            <div className="home-col">{tee.timeList?.msg}</div></>}

          {/* {tee.timeList?.data && tee.timeList?.data.map((item, index) => <Card data={item} key={index} id={index}
            modelData={setmodelData} />)} */}

          {/* {tee.timeList && tee.timeList?.data && getTimeFilterArray(tee.timeList?.data, hour_before, minute_before, hour_after, minute_after).map((item, index) => <Card data={item} key={index} id={item.golferDetails[0].teeTimeIndex}
            modelData={setmodelData} />)} */}

          {timeList_data && <InfiniteScroll
            dataLength={timeList_data.length} //This is important field to render the next data
            next={loadFunc}
            hasMore={tee.timeList?.data === null ? false : true}
            loader={<div className="text-center"><Loader /></div>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          // below props only if you need pull down functionality
          // refreshFunction={refresh}
          // pullDownToRefresh={false}
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
          >

            {timeList_data.map((item, index) => <Card data={item} key={index} id={item.teeTimeIndex ? item.teeTimeIndex : index}
              modelData={setmodelData} />)}
          </InfiniteScroll>}


          <Modal modelData={modelData} />

        </div>
      </section>
    </>
  );
};

export default TeeSheet;
