/* eslint-disable no-unused-expressions */
import React from "react";
import moment from "moment";

const Card = (props) => {
  const { teeTimeDatetime, golferDetails, id } = props.data;
  return <>
    <div className="golf-box mx-3" id={props.id}>
      <div className="golf-box-row golf-head" style={{
        backgroundColor: golferDetails.length <= 1 ? '#A9A9A9' : '',
      }}>
        <div className="golf-box-col">
          <h4 style={{
            color: golferDetails.length <= 1 ? '#4c4847' : '',
          }}>
            <img src={golferDetails.length <= 1 ? "images/watch-icon1.png" : "images/watch-icon.png"} alt="" /> {moment(teeTimeDatetime).format("LT")}
          </h4>
        </div>
      </div>
      {golferDetails.map((row, index) => {
        let obj = JSON.parse(JSON.stringify(row));
        obj.captainName = golferDetails[0].isCaptain ? golferDetails[0].playerShortName : null;
        return row.confirmationId === 0 ? null :
          <div className="golf-box-row" key={index}
            data-bs-toggle={(!row.isBooked && row.id !== 0) ? "modal" : ""}
            data-bs-target="#exampleModal"
            onClick={() => {
              (!row.isBooked && row.id !== 0) && props.modelData(obj);
            }}
            style={{
              backgroundColor: row.id === 0 ? '#A9A9A9' : ''
            }}>

            <div className="golf-box-col">
              <p>
                <span className="golf-n">{row.slotNumber}</span> <span style={{
                  fontWeight: row.isCaptain ? 600 : ""
                }}>
                  {row.id === 0 ? <></> : <>{!row.isCaptain && <i style={{ fontWeight: 300 }} className="fa fa-plus"></i>} {row.playerShortName}</>}
                </span>
              </p>
            </div>
            <div className="golf-box-col text-center" style={{ width: "7em" }}>
              {row.id !== 0 && <a
                type="button"
                className=""
                style={{
                  color: row.isBooked || row.isCaptain ? '#696969' : ''
                }}
              >
                {row.isBooked === true ? <i className="far fa-check-circle"></i> : "Select"}
              </a>}
            </div>
          </div>
      })}
    </div>
  </>
};

export default Card;
